body {
    background-color: var(--body-hg);
}

body {
    font-family: 'Roboto', "Helvetica Neue", sans-serif;
    line-height: 1.6;
}

.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body .n-dropdown-option-body__prefix.n-dropdown-option-body__prefix--show-icon {
    margin: 0 0.5rem !important;
}

.page-header {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.page-sub-header {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.header-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.page-header-icon {
    transform: translate(0, -2px) !important;
    margin-right: 0.5rem;
}

.three-pic-card-row {
    gap: 25px;
}

.three-pic-card-row .pic-card {
    flex: 1 1 0;
    border: none;
}

.three-pic-card-row .pic-card {
    background-color: unset;
}

.three-pic-card-row .pic-card .card-header {
    padding: .5rem 0;
    border: none;
    background-color: unset;
}

.three-pic-card-row .pic-card .card-body {
    padding: 1rem 0;
}

.alert {
    font-size: 100%;
    line-height: 1.6;
}

form .star {
    margin-left: 5px;
}

form .form-tretmiene {
    visibility: hidden;
    height: 1px;
}