:root {
    --bs-blue: rgba(13, 110, 253, 1);
    --bs-blue-0: rgba(13, 110, 253, 0);
    --bs-blue-hover: rgb(83, 153, 255);
    --bs-blue-05: rgba(13, 110, 253, 0.5);
    --bs-indigo: rgb(102, 16, 242);
    --bs-purple: rgb(111, 66, 193);
    --bs-pink: rgb(214, 51, 132);
    --bs-red: rgb(220, 53, 69);
    --bs-orange: rgb(253, 126, 20);
    --bs-yellow: rgb(255, 193, 7);
    --bs-green: rgb(25, 135, 84);
    --bs-teal: rgb(32, 201, 151);
    --bs-cyan: rgb(13, 202, 240);
    --bs-white: rgb(255, 255, 255);
    --bs-gray: rgb(108, 117, 125);
    --bs-gray-dark: rgb(52, 58, 64);
    --bs-primary: var(--bs-blue); /*rgb(13, 110, 253);*/
    --bs-secondary: rgb(108, 117, 125);
    --bs-success: rgb(25, 135, 84);
    --bs-info: rgb(13, 202, 240);
    --bs-warning: rgb(255, 193, 7);
    --bs-danger: rgb(220, 53, 69);
    --bs-light: rgb(248, 249, 250);
    --bs-dark: rgb(33, 37, 41);

    --alice-white: rgb(240, 248, 255);
    --bg-white-09: rgba(248, 249, 250, 0.9);
    --bg-white-08: rgba(248, 249, 250, 0.8);
    --bg-white-05: rgba(248, 249, 250, 0.5);
    --bg-white-03: rgba(248, 249, 250, 0.3);
    --bg-black: rgb(10, 10, 10);
    --bg-black-08: rgba(10, 10, 10, 0.8);
    --bg-black-07: rgba(10, 10, 10, 0.7);
    --bg-black-06: rgba(10, 10, 10, 0.6);
    --bg-black-05: rgba(10, 10, 10, 0.5);
    --txt-nav-gray-5: rgb(143, 148, 152);
    --page-text-blue: rgb(170, 204, 255);
    --page-text-dark-blue: rgb(16, 44, 59);
}

/*
    L I G H T   color theme
 */
html:not([dark]), html[dark=false] {
    --body-hg: var(--bs-white);
    --top-nav-link: var(--txt-nav-gray-5);
    --top-nav-bg: var(--bg-white-09);
    --top-nav-btn-hover-txt: var(--bs-light);
    --top-nav-btn-hover-bg: var(--bs-blue);
    --top-nav-lvl2-hover-txt: var(--bs-light);
    --top-nav-lvl2-hover-bg: var(--bs-blue-05);
    --top-nav-collapsed-lvl1-hover-txt: var(--bs-white);
    --top-nav-collapsed-lvl1-hover-bg: var(--bs-gray);
    --top-nav-collapsed-lvl2-txt: var(--bs-white);
    --top-nav-collapsed-lvl2-hover-txt: var(--bs-white);
    --page-headlines-1: var(--bs-blue);
    --page-text-blue-1: var(--page-text-dark-blue);
    --alert-info-txt: var(--bs-white);
    --alert-info-bg: var(--bs-blue);
    --form-star-color: var(--bs-blue);
    --page-footer-bg: var(--bs-gray-dark);
    --footer-image-bg: var(--bs-blue-0);
    --footer-image-hover-bg: var(--bs-blue);
}

/*
    D A R K   color theme
 */
html[dark=true] {
    --body-hg: var(--bg-black);
    --top-nav-link: var(--txt-nav-gray-5);
    --top-nav-bg: var(--bg-black-08);
    --top-nav-btn-hover-txt: var(--bs-light);
    --top-nav-btn-hover-bg: var(--bs-blue);
    --top-nav-lvl2-hover-txt: var(--bs-blue);
    --top-nav-lvl2-hover-bg: var(--bg-white-03);
    --top-nav-collapsed-lvl1-hover-txt: var(--top-nav-link);
    --top-nav-collapsed-lvl1-hover-bg: var(--bs-gray-dark);
    --top-nav-collapsed-lvl2-txt: var(--bg-white-03);
    --top-nav-collapsed-lvl2-hover-txt: var(--bs-blue);
    --page-headlines-1: var(--alice-white);
    --page-text-blue-1: var(--page-text-blue);
    --alert-info-txt: var(--bs-white);
    --alert-info-bg: var(--bs-blue);
    --form-star-color: var(--bs-blue);
    --page-footer-bg: var(--bs-dark);
    --footer-image-bg: var(--bs-blue-0);
    --footer-image-hover-bg: var(--bs-blue);
}


.page-header, .page-sub-header, .pic-card .card-title {
    color: var(--page-headlines-1);
}

.page-header-icon {
    color: var(--bs-orange);
}

.body-text, .pic-card .card-text {
    color: var(--page-text-blue-1);
}

.btn-primary {
    background-color: var(--bs-blue) !important;
}

.btn-primary:hover {
    color: var(--bs-white) !important;
    background-color: var(--bs-blue-hover) !important;
    border-color: var(--bs-blue-hover) !important;
}

.alert-info {
    color: var(--alert-info-txt);
    background-color: var(--alert-info-bg);
    border-color: unset;
}

form .star {
    color: var(--form-star-color);
}