/* teko-300 - latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/teko-v10-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Teko'),
    url('../assets/fonts/teko-v10-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/teko-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/teko-v10-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/teko-v10-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/teko-v10-latin-300.svg#Teko') format('svg'); /* Legacy iOS */
}

/* teko-regular - latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/teko-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Teko'),
    url('../assets/fonts/teko-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/teko-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/teko-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/teko-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/teko-v10-latin-regular.svg#Teko') format('svg'); /* Legacy iOS */
}

/* teko-500 - latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/teko-v10-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Teko'),
    url('../assets/fonts/teko-v10-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/teko-v10-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/teko-v10-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/teko-v10-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/teko-v10-latin-500.svg#Teko') format('svg'); /* Legacy iOS */
}

/* teko-600 - latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/teko-v10-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Teko'),
    url('../assets/fonts/teko-v10-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/teko-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/teko-v10-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/teko-v10-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/teko-v10-latin-600.svg#Teko') format('svg'); /* Legacy iOS */
}

/* teko-700 - latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/teko-v10-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Teko'),
    url('../assets/fonts/teko-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/teko-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/teko-v10-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/teko-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/teko-v10-latin-700.svg#Teko') format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300 - greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Condensed'),
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-700 - greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Condensed'),
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-700.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300italic - greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: italic;
    font-weight: 300;
    src: url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Condensed'),
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/open-sans-condensed-v15-greek-ext_greek_cyrillic-ext_cyrillic-300italic.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}

