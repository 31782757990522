.n-icon {
    transform: translateZ(0) translateY(-1px) !important;
}

.n-form-item.switch-right-labeled {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: auto auto;
    justify-items: left;
    align-items: center;
}

.n-form-item.switch-right-labeled .n-form-item-blank {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.n-form-item.switch-right-labeled .n-form-item-label {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    padding-top: 3px;
    text-align: left;
}

.n-form-item.switch-right-labeled .n-form-item-feedback-wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
}