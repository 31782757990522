:root {
    --nav-color-transition-property: color, background-color, padding;
    --nav-color-transition-duration: 500ms;
    --nav-color-transition-delay: 100ms;
    --nav-color-transition-t-func: ease-out;
}

.dropdown-menu.show {
    animation: fadeInUp;
    animation-duration: 200ms;
}

.swiper-pagination-bullet-active, .swiper-button-prev, .swiper-button-next {
    color: var(--bs-blue);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
